/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import PropTypes from "prop-types";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import { Link } from "gatsby";

const PostCard = ({ post, index }) => {
    const url = `/${post.slug}/`;
    const readingTime = readingTimeHelper(post);
    const isHero = true;
    // index === 0 || index % 5 === 0
    const getTagClasses = () =>
        post.tags.map(({ slug }) => `tag-${slug}`).join(" ");

    return (
        <article
            className={`item ${isHero ? "is-hero" : "is-odd"} post ${
                post.feature_image ? "is-image" : "no-image"
            } ${getTagClasses()}`}
        >
            <div className="item-container global-color">
                {post.feature_image && isHero && (
                    <Link
                        to={url}
                        className="item-image global-image global-color"
                    >
                        <img src={post.feature_image} style={{ width: "500px" }} className="post-image" />
                    </Link>
                )}
                <div className="item-content">
                    {post.feature_image && !isHero && (
                        <Link
                            to={url}
                            className="item-image global-image global-color"
                        >
                            <img src={post.feature_image} />
                        </Link>
                    )}
                    {isHero && (
                        <div className="global-meta">
                            {post.published_at_from_now} by{" "}
                            {/* eslint-disable-next-line react/prop-types */}
                            <Link to={`/author/${post.primary_author.slug}`}>
                                {post.primary_author.name}
                            </Link>{" "}
                            <span className="global-reading">
                                — {readingTime}
                            </span>
                        </div>
                    )}
                    <h2 className="item-title">
                        <Link to={url} className="global-underline">
                            {post.title}
                        </Link>
                    </h2>
                    {!isHero && (
                        <div className="global-meta">
                            {/* eslint-disable-next-line react/prop-types */}
                            <Link to={`/author/${post.primary_author.slug}`}>
                                {post.primary_author.name}
                            </Link>{" "}
                            <span className="global-reading">
                                — {readingTime}
                            </span>
                        </div>
                    )}
                    <p className="item-excerpt">{post.excerpt}</p>
                    {post.tags && (
                        <div className="global-tags">
                            {" "}
                            {post.tags.map(tag => (
                                <>
                                    {!tag.slug.includes("hash") && (
                                        <Link
                                            key={tag.slug}
                                            to={`/tag/${tag.slug}`}
                                            className="global-tags-hash-sign"
                                        >
                                            {tag.name}
                                        </Link>
                                    )}
                                </>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </article>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        published_at_from_now: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
};

export default PostCard;
